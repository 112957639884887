<!-- eslint-disable max-len -->
<template>
	<div>
		<template v-if="loadingCountries">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<div class="main__header">
				<div class="main__primary">
					<h3>Country</h3>
				</div>
				<div class="form__search-wrapper">
					<label class="form__label" for="search-user">
						<input
							id="search-user"
							class="form-control form__input form__search"
							type="search"
							v-model="query"
							@keyup.enter="search"
							placeholder="Type in to search for a Country"
						/>
						<span class="form__search__icon">
							<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<title>Search Icon</title>
								<path
									d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 
									9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 
									0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
									fill="#828282"
								/>
							</svg>
						</span>
					</label>
					<small class="error-feedback">{{ queryError }}</small>
				</div>
				<div class="d-flex">
					<button
						class="btn btn__primary"
						type="button"
						name="button"
						data-toggle="modal"
						data-target="#activityModal"
						id="addCountryModal"
					>
						<span class="btn__icon">
							<svg width="24" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M8 5H5V2H3v3H0v2h3v3h2V7h3V5zm10 1c1.66 0 2.99-1.34 2.99-3S19.66 0 18 0c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86 0 1.07-.34 2.04-.9 2.86.28.09.59.14.91.14zm-5 0c1.66 0 2.99-1.34 2.99-3S14.66 0 13 0c-1.66 0-3 1.34-3 3s1.34 3 3 3zm6.62 2.16C20.45 8.89 21 9.82 21 11v2h3v-2c0-1.54-2.37-2.49-4.38-2.84zM13 8c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3z"
									fill="#fff"
								/>
							</svg>
						</span>
						Add Country
					</button>
				</div>
			</div>
			<section class="main-grid__row contacts contacts--list">
				<div class="contacts--list__wrapper">
					<div class="contacts--list__item head">
						<div></div>
						<p>#</p>
						<p>Name</p>
						<p>Currency</p>
						<p>Dial Code</p>
						<p>Action</p>
					</div>
					<div class="contacts__body">
						<template v-if="countriesLoaded && searchLoaded && countries.total > 0">
							<CountryItem v-for="country in countries.data" :key="country.id" :countryData="country" />
						</template>
						<template v-else-if="!searchLoaded && getSearchCountries.length > 0">
							<CountryItem
								v-for="country in getSearchcountries"
								:key="country.id"
								:countryData="country"
							/>
						</template>
						<template v-else-if="!searchLoaded && getSearchCountries.length === 0">
							<p class="mt-5 pl-5">No Country found</p>
						</template>
						<template v-else-if="countriesLoaded">
							<p class="mt-5 pl-5">No Country found</p>
						</template>
						<template v-else>
							<p class="alert alert-danger">
								An error occurred while loading countries, please try again.
							</p>
						</template>
					</div>
				</div>
			</section>
			<Pagination :options="{ limit, offset, total, baseUrl }" v-if="total > limit" />
			<AddCountry />
		</template>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loader from '../../components/Loader';
import AddCountry from './AddCountry';
import Pagination from '../../components/Pagination';
import CountryItem from './CountryItem';

export default {
	name: 'Countries',
	components: {
		Loader,
		AddCountry,
		Pagination,
		CountryItem
	},
	data() {
		return {
			countriesLoaded: false,
			loadingCountries: true,
			offset: 0,
			limit: 10,
			baseUrl: 'countries',
			query: '',
			searchLoaded: true,
			total: 0,
			queryError: ''
		};
	},
	created() {
		this.limit = this.$route.query.limit ? this.$route.query.limit : this.limit;
		this.offset =
			this.$route.query.offset || this.$route.query.offset === 0 ? this.$route.query.offset : this.offset;
		this.fetchAllCountries([this.limit, this.offset]);
	},
	watch: {
		status(value) {
			if (value === 'success') {
				this.loading = false;
				this.loadingCountries = false;
				this.query = '';
				this.countriesLoaded = true;
				this.total = this.searchLoaded ? this.totalCountries : this.searchTotal;
			}
		},
		$route() {
			this.limit = this.$route.query.limit ? this.$route.query.limit : 10;
			this.offset = this.$route.query.offset ? this.$route.query.offset : 0;
			this.fetchAllCountries([this.limit, this.offset]);
		},
		query() {
			this.queryError = '';
		}
	},
	computed: {
		...mapGetters({
			countries: 'getCountries',
			status: 'getStatus',
			totalCountries: 'getTotalCountries',
			getsearchCountries: 'getCountriesSearch'
		})
	},
	methods: {
		...mapActions(['fetchAllCountries', 'searchCountries']),
		search() {
			if (this.query.length === 0) {
				this.fetchAllCountries([this.limit, this.offset]);
				this.searchLoaded = true;
			} else if (this.query.length < 3) {
				this.queryError = 'Search parameter must be at least 3 characters long';
			} else {
				this.limit = this.$route.query.limit ? this.$route.query.limit : this.limit;
				this.offset =
					this.$route.query.offset || this.$route.query.offset === 0 ? this.$route.query.offset : this.offset;
				const data = { limit: this.limit, offset: this.offset, query: this.query };
				this.searchCountries(data);
				this.searchLoaded = false;
				this.total = this.searchTotal;
				this.queryError = '';
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.contacts--list__item {
	display: grid;
	grid-template-columns: 35px 0.2fr 1fr 1fr 1fr 150px !important;
}
</style>
