<template>
	<div
		id="activityModal"
		aria-labelledby="contactModalTitle"
		class="modal fade"
		tabindex="-1"
		role="dialog"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header modal__header">
					<h3 class="modal-title" id="userModalTitle">Add Country</h3>
					<button
						@click.prevent="clearErrors()"
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="close"
					>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body modal__body">
					<form class="modal__form" method="post" @submit.prevent="addCountry">
						<template v-if="getErrorLog.message">
							<div class="invalid-feedback alert alert-danger text-center" role="alert">
								{{ getErrorLog.message }}
							</div>
						</template>
						<div class="form-group">
							<label class="modal__label" for="name">Name</label>
							<input
								:class="[{ 'is-invalid': errors.name }]"
								@keydown="clearErrors($event.target.name)"
								v-model="itemData.name"
								name="name"
								value=""
								type="text"
								class="form-control"
								id="name"
								placeholder="Name"
							/>
							<span class="modal__form__icon">
								<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<title>Person Icon</title>
									<path fill="none" d="M0 0h24v24H0z" />
									<path fill="none" d="M4 4h16v16H4z" />
									<path
										d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 
										2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z"
										fill="#828282"
									/>
								</svg>
							</span>
							<div class="invalid-feedback">
								{{ errors.name }}
							</div>
						</div>
						<div class="form-group">
							<label class="modal__label" for="currencyCode">Currency Code</label>
							<input
								:class="[{ 'is-invalid': errors.currency_code }]"
								@keydown="clearErrors($event.target.name)"
								v-model="itemData.currency_code"
								value=""
								name="currency_code"
								type="text"
								class="form-control"
								id="currencyCode"
								placeholder="Currency Code"
							/>
							<span class="modal__form__icon">
								<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<title>Person Icon</title>
									<path fill="none" d="M0 0h24v24H0z" />
									<path fill="none" d="M4 4h16v16H4z" />
									<path
										d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 
									2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z"
										fill="#828282"
									/>
								</svg>
							</span>
							<div class="invalid-feedback">
								{{ errors.currency_code }}
							</div>
						</div>
						<div class="form-group">
							<label class="modal__label" for="dialCode">Dial Code</label>
							<input
								:class="[{ 'is-invalid': errors.dial_code }]"
								@keydown="clearErrors($event.target.name)"
								v-model="itemData.dial_code"
								name="dial_code"
								value=""
								type="text"
								class="form-control"
								id="dialCode"
								placeholder="Dial Code"
							/>
							<span class="modal__form__icon">
								<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<title>Phone Icon</title>
									<path fill="none" d="M0 0h24v24H0z" />
									<path
										d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 
											1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 
											0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 
											3.57.11.35.03.74-.25 1.02l-2.2 2.2z"
										fill="#828282"
									/>
								</svg>
							</span>
							<div class="invalid-feedback">
								{{ errors.dial_code }}
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer modal__footer">
					<button
						@click.prevent="clearErrors()"
						class="btn btn--md"
						type="button"
						data-dismiss="modal"
						aria-label="close"
						name="button"
					>
						Cancel
					</button>
					<button
						@click.prevent="addCountry"
						class="btn btn--md btn__primary"
						type="button"
						v-bind="{ disabled: loading }"
						name="button"
						id="addCountry"
					>
						Add Country
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import form from '../../mixins/form';
import country from '../../utils/validations/countries';

export default {
	name: 'AddCountry',
	mixins: [form],
	data() {
		return {
			loading: false,
			itemData: {},
			errors: {}
		};
	},

	computed: {
		...mapGetters(['getStatus', 'getErrorLog'])
	},
	watch: {
		getStatus(value) {
			if (value === 'success') {
				this.clearFormField();
			}
		}
	},
	methods: {
		...mapActions(['createCountry']),
		addCountry() {
			this.validate(this.itemData, country.create);
			if (Object.keys(this.issues).length > 0) {
				this.errors = this.issues;
				return false;
			}
			this.createCountry(this.itemData);
			return true;
		}
	}
};
</script>

<style lang="scss" scoped>
select {
	height: 50px;
}
</style>
